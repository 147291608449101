.App-logo {
  width: 158px;
  height: 20px;
  max-height: 20px;
  /* pointer-events: none; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px 20px;
  background-color: #091B2C;
}

.App-link {
  color: #61dafb;
}

body {
  background-color: #ECEFF1;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* Circular Loader */
.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 8px;
  height: 8px;
  margin: 70px auto;
  opacity: 0.5;
  border-radius: 50%;
  box-shadow:
    0 -14px 0 rgba(16,112,215,0.8),
    10px -10px 0 rgba(16,112,215,0.7),
    14px 0px 1px rgba(16,112,215,0.6),
    10px 10px 1px rgba(16,112,215,0.5),
    0px 14px 2px rgba(16,112,215,0.4),
    -10px 10px 2px rgba(16,112,215,0.3),
    -14px 0px 3px rgba(16,112,215,0.2),
    -10px -10px 3px rgba(16,112,215,0.1);
  transform-origin: 50%;
  animation: load 1s steps(8, end) infinite;
}

@keyframes load {
  0% { transform: rotate(0); }
  100% { transform: rotate(360deg); }
}